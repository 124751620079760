<template>
  <div class="page-container">
    <HeaderComp></HeaderComp>

    <div class="content">
      <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
    </div>

    <FooterComp v-if="shouldRender"></FooterComp>
  </div>
</template>

<script lang="ts" scoped>
import HeaderComp from './components/Common/HeaderComponent.vue'
import { defineAsyncComponent, nextTick } from 'vue'
import { onIdle } from './utils/OnIdle';

export default {
  name: 'App',
  data() {
    return {
      shouldRender: false
    }
  },
  components: {
    HeaderComp,
    FooterComp: defineAsyncComponent(() => import('./components/Common/FooterComponent.vue'))
  },
  mounted() {
    onIdle(() => {
        this.shouldRender = true;
      });
  }
}
</script>

<style scoped>
html,
body {
  height: 100%;
  margin: 0;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
  margin-bottom: 64px;
}
</style>
