import type { SweetAlertIcon } from 'sweetalert2';
import type { App } from 'vue';

export default {
  install: (app: App) => {
    app.config.globalProperties.$msg = async (icon: SweetAlertIcon, text: string) => {
      const { default: Swal } = await import('sweetalert2');

      Swal.fire({
        toast: true,
        position: 'bottom-left',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (popup: HTMLElement) => {
          popup.addEventListener('mouseenter', Swal.stopTimer);
          popup.addEventListener('mouseleave', Swal.resumeTimer);
        },
        icon: icon,
        text: text,
      });
    };
  },
};
